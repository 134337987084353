ol {
  padding: 0;
}
.Facts-plan {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 2s ease;
}

.MasterTitle {
  width: 100%;
  font-size: 66px;
  margin-bottom: 0px;
  text-align: center;
}

.SubTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 300;
  margin-bottom: 50px;
}

.ItemS {
  color: #fff;
  display: flex;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 240px;
  flex: auto;
}

.Trusten {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageIcon:hover {
  transform: scale(1.2);
  transition: 0.4;
}
.imageIcon {
  display: flex;
  width: 100px;
  height: 100px;
  transition: transform 0.3s ease;
}

@media screen and (max-width: 796px) {
  .Facts-plan {
    margin: 0px 40px 0px 20px;
  }

  .MasterTitle {
    margin-top: 90px;
    font-size: 42px;
    text-align: center;
  }
  .SubTitle {
    text-align: center;
  }  
  .ItemS {
    display: flex;
    width: 240px;
  }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}