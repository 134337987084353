.Container-X {
  color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 32vh;
  margin-left: 32vh;
  text-align: center;
  margin-bottom: 100px;
  animation: fadeIn 2s ease;
}

.title-I {
  font-size: 60px;
  margin-bottom: 10px;
}

.Context-I {
  font-weight: 400;
  margin-bottom: 50px;
  text-align: justify;
}

@media screen and (max-width:769px) {
  .title-I {
    font-size: 50px;
  }
  .Container-X {
    margin-right: 40px;
    margin-left: 40px;
  }
  .Context-I {
    text-align: justify;
  }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}