.container-t3 {
  color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2s ease;
}

.textInt-3 {
  margin-top: 70px;
  font-size: 30px;
  margin: 20px 40px 10px 40px;
  text-align: center;
}

.titleCoberture-3 {
  margin: 40px 40px 40px 40px;
  font-size: 60px;
  text-align: center;
}

.CardS {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px 40px 14px 40px;
  width: 350px;
  height: 180px;
  border-radius: 18px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.CardS:hover {
  transform: scale(1.2);
  transition: 0.4;
}


.TheDivision {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 60, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  max-width: 500px;
  height: 5px;
  margin: 30px 0px 70px 0px;
}

.Card-1 {
  background-image: url(./Mask1.svg);
  background-size: 490px;
  background-color: #2b80f6;
}

.Card-2 {
  background-image: url(./Mask2.svg);
  background-size: 490px;
  background-color: #8b46f3;
}
.Card-3 {
  background-image: url(./Mask3.svg);
  background-size: 490px;
  background-color: #fc2c50;
}

.plan-name {
  margin-top: 0;
  margin-top: 0;
  font-weight: bold;
  font-style: italic;
  font-size: 36px;
  line-height: 0px;
}

.iconS-M {
  font-size: 30px;
  margin-left: 240px;
}

.plan-cost {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 28px;
  font-weight: bold;
  color: #00003c;
  line-height: 12px;
}

.Foot-card {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.Residential-Block {
  width: 72%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.Comercial-Block {
  width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.Corporative-Block {
  width: 72%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.plan-Mbs {
  text-align: flex-end;
}
.plan-type {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 1300px) {
  .CardS {
    margin-bottom: 40px;
    animation: none;
  }

  .textInt-3 {
    margin-top: 10px;
    font-size: 25px;
    text-align: center;
  }

  .Residential-Block {
    width: 100%;
    display: flex;
  }
  .Comercial-Block {
    width: 70%;
    display: flex;
  }

  .Corporative-Block {
    width: 100%;
    display: flex;
  }
  .TheDivision {
width: 100%;
  }
}
@media screen and (max-width: 769px) {

  .titleCoberture-3 {
    font-size: 42px;
    text-align: center;
  }

  .CardS {
    margin-bottom: 40px;
  }

  .Residential-Block {
    width: 100%;
    display: flex;
    flex-direction: column;

  }
  .Comercial-Block {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .Corporative-Block {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .TheDivision {
width: 100%;
  }
}