.Content-Block-t2 {
    margin-top: 100px;
    margin-bottom: 100px;
    background: linear-gradient(to right,  #16195f, #00003c 40%);
    height: 40em;
    animation: fadeIn 2s ease;
}

.Content-Block-t2 {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.asset-A-t2 {
    max-width: 100%;
    width: 30%;
    margin-right: 70px;
    transition: transform 0.3s ease;
}

.asset-A-t2:hover {
    transform: scale(1.2);
    transition: 0.4;
  }

.titleH2-t2 {
    font-size: 60px;
}

.textInt-t2 {
    text-align: justify;
    width: 450px;
}

.information-block-t2 {
    display: flex;
    font-size: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: justify;
    color: white;
    
}

@media screen and (max-width: 1200px) {
    .Content-Block-t2 {
        display: flex;
        flex-direction: column;
        height: 52em;
    }
    .titleH2-t2 {
        margin-top: 40px;
        font-size: 62px;
    }
    .asset-A-t2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    .textInt-t2 {
        text-align: justify;
        width: 50%;
    }
    .information-block-t2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
    }
  }

@media screen and (max-width:769px) {
    .Content-Block-t2 {
        display: flex;
        flex-direction: column;
        height: 52em;
    }
    .titleH2-t2 {
        margin-top: 40px;
        font-size: 42px;
        text-align: left;
        width: 82%;
    }
    .asset-A-t2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin-left: 60px;
    }
    .textInt-t2 {
        text-align: justify;
        width: 85%;
    }
    .information-block-t2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }