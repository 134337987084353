.container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: right;

}
.Image {
        position: absolute;
        z-index: -2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
        animation: fadeIn 6s ease;
}
.ContainerText {
    margin-right: 80px;
    text-align: right;
    /* padding-top: 80px; */
    animation: fadeIn 2s ease;
}

.StarText {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: #ffff;
    margin-top: 0;
    margin-bottom: 8px;
    line-height: 1em;
}

.TitleStar {
    font-size: 76px;
    font-weight: 100;
}
.Subtitle {
    padding-top: 10px;
    max-width: 16em;
    font-size: 54px;
}
.Content {
    padding-top: 10px;
    font-size: 20px;
    margin-top: 10px;
}

.ButtonMore {
    display: inline-block;
    width: auto;
    margin-top: 20px;
    color: #ffff;
    text-decoration: none;
    background-color: rgb(88, 92, 204);
    font-size: 20px;
    padding: 10px 60px 10px 60px;
    border-radius: 6px;
}

@media screen and (max-width:600px) {
    .container {
        width: 100%;
        height: 700px;
    }
    .TitleStar {
        font-size: 46px;
    }
    .Subtitle {
        font-size: 44px;
    }
    .Content {
        font-size: 18px;
    }
    .ContainerText {
        margin-right: 30px;
        text-align: right;
         padding-top: 40px;
        animation: fadeIn 2s ease;
    }
    
    .Image {
        position: absolute;
        z-index: -2;
        top: 50px;
        width: 100%;
        height: 100%;
    }

}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }