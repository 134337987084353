.Content-T {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d0f46;
  height: 45em;
  animation: fadeIn 2s ease;
}

.Content-T2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45em;
  animation: fadeIn 2s ease;
}

.titleCoberture-1 {
  text-align: flex-start;
  font-size: 50px;
  color: #ffff;
}

.information-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 530px;
  margin-left: 40px;
}

.information-block-C {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 530px;
  margin-left: 40px;
}

.titleCoberture-2 {
  text-align: flex-start;
  font-size: 50px;
  color: #ffff;
}

.textInt-1 {
  font-size: 18px;
  text-align: flex-start;
  color: #ffff;
}
.textInt-2 {
  line-height: 36px;
  font-size: 18px;
  text-align: flex-start;
  color: #ffff;
}


@media screen and (max-width: 1200px) {
  .Content-T {
    display: flex;
    flex-direction: column;
    height: 65em;
  }
  .titleCoberture-1 {
    margin-top: 50px;
    text-align: center;
  }
  .textInt-1 {
    font-size: 18px;
    text-align: justify;
    color: #ffff;
  }
}

@media screen and (max-width: 769px) {
  .Content-T2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 80px 0px 80px 0px;
  }
  .map-responsive {
    overflow: hidden;
    padding-bottom: 250px;
    position: relative;
    height: 0;
  }
  .map-responsive iframe {
    display: flex;
    left: 0;
    top: 0;
    height: 250px;
    width: 380px;
  }
  .information-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0px;
    padding: 0px 30px 0px 30px;
  }
  .Content-T {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 55em;
  }
  .map-responsive-C2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    ;
  }
  .map-responsive-C {
    overflow: hidden;
    margin-bottom: 0px;
    position: relative;
  }
  .map-responsive-C iframe {
    display: flex;
    left: 0;
    top: 0;
    height: 250px;
    width: 380px;
  }

  .information-block-C {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0px;
    padding: 0px 30px 0px 30px;
    margin-top: -100px;
  }
  .titleCoberture-1 {
    text-align: left;
    font-size: 42px;
  }
  .textInt-1 {
    font-size: 16px;
    text-align: justify;
  }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}