.Content-Block {
    margin-top: 100px;
    background: linear-gradient(to right, #00003c 40%, #16195f);
    height: 47em;
    animation: fadeIn 2s ease;
}
.Content-Block {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.asset-A {
    max-width: 100%;
    width: 30%;
    margin-left: 60px;
    transition: transform 0.3s ease;
}

.asset-A:hover {
    transform: scale(1.2);
    transition: 0.4;
  }

.titleH2 {
    width: 100%;
    font-size: 60px;
}

.information-block {
    display: flex;
    font-size: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: justify;
    color: white; 
}

@media screen and (max-width: 1200px) {
    .Content-Block {
        display: flex;
        flex-direction: column;
        height: 62em;
    }
    .titleH2 {
        margin-top: 40px;
        font-size: 62px;
    }
    .asset-A {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        margin-left: 20px;
        margin-top: 20px;
    }
    .information-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
    }
    
  }

@media screen and (max-width:769px) {
    .Content-Block {
        display: flex;
        flex-direction: column;
        height: 62em;
    }
    .titleH2 {
        margin-top: 40px;
        font-size: 42px;
        text-align: center;
    }
    .asset-A {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin-left: 20px;
        margin-top: 20px;
    }
    .information-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
    }
    
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }