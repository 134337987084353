.Foot-S {
    background-color: #0d0f46;
    color: #ffff;
    display: flex;
    justify-content: space-evenly;
    padding: 50px;
}
.listNav {
    list-style: none;
    padding-left: 0px;
}

.TitleS-S {
    color: #ac1818;
    
}
.Element-List {
    display: inline-block;
    text-decoration: none;
    color: #ffff;
    padding-left: 0;
}

@media screen and (max-width: 769px) {
    .Foot-S {
        display: flex;
        flex-direction: column;
        padding: 50px;
    }
}