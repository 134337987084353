.NavigationBar-Container {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.StarLetters {
  color: white;
  text-decoration: none;
  padding-left: 30px;
  font-weight: 600;
  font-size: 18px;
  transition: transform 0.3s ease;
}

.StarLetters:hover {
  color:#585ccc;
  transform: scale(1.2);
  transition: 0.4;
}

.NavBar-Comp {
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  margin: 18px;
  padding: 14px 24px 14px 24px;
  width: 100%;
  justify-content: space-between;
}
.Nav-Left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Nav-rigth {
  display: flex;
}

.iconStar {
  width: 110px;
  display: none;
}
.iconStar2 {
  width: 110px;
  display: block
}

.Effect {
  mix-blend-mode: color-dodge;
}

.SiIcon {
  color: #ffff;
  font-size: 25px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.SiIcon:hover {
  color:#585ccc;
  transform: scale(1.2);
  transition: 0.4;
}

.Extend-Menu {
 display: none;
}

.Extend-Menu i:hover {
  color:#585ccc;
 }

.Extend-Menu i {
  color: #ffff;
  font-size: 28px;
  align-items: center;
  margin-left: 10px;
}


@media screen and (max-width:769px){
  .Nav-Left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 80px;
    right: -300px;
    display: none;
    width: 200px;
    height: 25vh;
    background-color: #ffff;
    padding: 20px 0px 0px 20px;
    border-radius: 10px;
  }

  #Nav-Left-active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    right: 0px;
  }

  .Nav-Left a {
    margin-bottom: 10px;
    color: #585ccc;
  }
  .Extend-Menu {
    display: block;
   }
  .iconStar {
    display: block;
  }
  .iconStar2 {
    display: none;
  }
}